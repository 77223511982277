body {
  margin: 0;
  font-family: 'Oswald';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
}

code {
  font-family: 'Oswald';
}

.borderCorners {
  position: relative;
}

.borderCorners:after {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  left: -5px;
  border-top: 3px solid #ff0000eb;
  border-left: 3px solid #ff0000eb;
}
.borderCorners:before {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -5px;
  right: -5px;
  border-bottom: 3px solid #ff0000eb;
  border-right: 3px solid #ff0000eb;
}


.image-container {
  width: 100%;
  height: inherit;
  cursor: zoom-in;
}

.image-container.open {
  cursor: zoom-out;
}

.image-container .shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.9);
}

.image-container.open .shade {
  pointer-events: auto;
  opacity: 0.8;
  z-index: 1550;
}


.image-container img {
  width: 100%;
  height: inherit;
  object-fit: cover;
}

.image-container.open img {
  position: fixed;
  width: auto;
  height: auto;
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
  z-index: 1551;
  padding-top: 3%;
  padding-bottom: 3%;
  transform: translate(-50%, -50%);
  left: 50%;
  top:50%;
}
